var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto rounded-lg",attrs:{"outlined":"","id":"user-logs","max-width":"1400"}},[_c('v-expansion-panels',{attrs:{"value":_vm.showDataTable ? 0 : null,"tile":"","flat":"","accordion":"","readonly":_vm.companyView},on:{"change":function($event){return _vm.$emit('showDataTable', $event === 0)}}},[_c('v-expansion-panel',{attrs:{"readonly":_vm.companyView}},[_c('v-expansion-panel-header',{staticClass:"pa-0 pr-6",attrs:{"hide-actions":_vm.companyView}},[_c('v-card-title',[_vm._t("table-title")],2)],1),_c('v-expansion-panel-content',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.table_headers,"items":_vm.table_records,"fixed-header":"","loading":_vm.loadingLogs,"mobile-breakpoint":"900","items-per-page":_vm.itemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.itemsOptions },"height":_vm.tableHeight,"sort-by":"date","sort-desc":true,"show-expand":"","single-expand":false,"item-key":"index"},on:{"update:options":function($event){_vm.tableOptions = $event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$ml.get("no_data"))+" ")]},proxy:true},(_vm.companyView)?{key:"header.author_user",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey darken-1","dark":"","small":""},domProps:{"textContent":_vm._s('mdi-help-circle')}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("author_description")))])])]}}:null,(_vm.companyView)?{key:"item.author_user",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(_vm.isNotSystem(item))?_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"28"}},[_c('img',{attrs:{"src":_vm.getUserPhoto(
                      item.author_user ||
                        item.status.target_user ||
                        item.status.logged_out_user
                    ),"alt":item.username}})]):_vm._e(),(
                  item.status.code === _vm.backendLogCodes.LOST_USER_SINGLE_ACCESS
                )?_c('span',[_vm._v(" "+_vm._s(item.status.target_user)+" ")]):(_vm.isGroupCode(item.status.code))?_c('span',[_vm._v(" "+_vm._s(item.author_user || _vm.$ml.get("user_not_found"))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.author_user || item.status.logged_out_user || _vm.$ml.get("system"))+" ")])],1)]}}:null,{key:"header.event",fn:function(ref){
                var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" ")]}},{key:"item.event",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.event)+" ")])]}},{key:"header.description",fn:function(ref){
                var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" ")]}},{key:"item.description",fn:function(ref){
                var item = ref.item;
return [(_vm.loginAttemptCodes.includes(item.status.code))?_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get(item.status.code.toLowerCase()))+" ")]):(item.status.code === _vm.backendLogCodes.ADD_NEW_USER)?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$ml.get("system"))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("add_new_user"))+" "),_c('b',[_vm._v(_vm._s(_vm.getUserEmail(item.status.target_user)))])])]):(item.status.code === _vm.backendLogCodes.DELETE_USER)?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$ml.get("system"))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("delete_user"))+" "),_c('b',[_vm._v(_vm._s(_vm.getUserEmail(item.status.target_user)))])])]):(item.status.code === _vm.backendLogCodes.UPDATE_USER)?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$ml.get("system"))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("update_user"))+" "),_c('b',[_vm._v(_vm._s(_vm.getUserEmail(item.status.target_user)))])])]):(
                item.status.code === _vm.backendLogCodes.USER_LOGOUT_SUCCESS
              )?_c('span',[_vm._v(" "+_vm._s(item.event)+" ")]):(item.status.code === _vm.backendLogCodes.LOGOUT_SUCCESS)?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.author_user))]),_vm._v(" "+_vm._s(_vm.$ml.get("force_logout_description"))+" "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.status.logged_out_user))])]):(
                item.status.code === _vm.backendLogCodes.CHANGE_USER_PASSWORD
              )?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.author_user)+" ")]),_vm._v(" "+_vm._s(_vm.$ml.get("changed_the_password_of"))+" "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.status.target_user)+" ")])]):(
                item.status.code === _vm.backendLogCodes.CHANGE_OWN_PASSWORD
              )?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.author_user)+" ")]),_vm._v(" "+_vm._s(_vm.$ml.get("change_own_password"))+" ")]):(
                [
                  _vm.backendLogCodes.ADVANCED_AUTO_LOGOUT,
                  _vm.backendLogCodes.AUTO_LOGOUT ].includes(item.status.code)
              )?_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("closed_office"))+" ")]):(
                item.status.code ===
                _vm.backendLogCodes.AUTO_LOGOUT_AFTER_CLOSING_WINDOW
              )?_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("closed_office"))+" ("+_vm._s(_vm.$ml.get("auto_logout_after_closing_window_description"))+") ")]):(
                item.status.code === _vm.backendLogCodes.SINGLE_ACCESS_LOGOUT
              )?_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("end_of_single_access"))+" ")]):(
                item.status.code === _vm.backendLogCodes.PONTOMAIS_AUTO_LOGOUT ||
                item.status.code === _vm.backendLogCodes.SENIOR_AUTO_LOGOUT
              )?_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("pontomais_auto_logout_description"))+" ")]):(
                item.status.code === _vm.backendLogCodes.ADD_USERS_TO_GROUP
              )?_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("add_users_to_group"))+" "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getGroupsNames(item.status.group))+": ")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getUsersEmails(item.status.users))+" ")])]):(
                item.status.code === _vm.backendLogCodes.REMOVE_USERS_FROM_GROUP
              )?_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("remove_users_from_group"))+" "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getGroupsNames(item.status.group))+": ")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getUsersEmails(item.status.users))+" ")])]):(
                item.status.code === _vm.backendLogCodes.ADD_GROUPS_TO_USER
              )?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getUsersEmails(item.status.users))+" ")]),_vm._v(" "+_vm._s(_vm.$ml.get("user_added_on_shifts"))+": "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getGroupsNames(item.status.group))+" ")])]):(
                item.status.code === _vm.backendLogCodes.REMOVE_GROUPS_FROM_USER
              )?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getUsersEmails(item.status.users))+" ")]),_vm._v(" "+_vm._s(_vm.$ml.get("user_removed_from_shifts"))+": "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getGroupsNames(item.status.group))+" ")])]):(
                item.status.code === _vm.backendLogCodes.ADDED_GROUP_RULES
              )?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.author_user)+" ")]),_vm._v(" "+_vm._s(_vm.$ml.get("user_added_group_rules"))+": "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getGroupsNames(item.status.group))+" ")])]):(
                item.status.code === _vm.backendLogCodes.REMOVED_GROUP_RULES
              )?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.author_user)+" ")]),_vm._v(" "+_vm._s(_vm.$ml.get("user_removed_group_rules"))+": "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getGroupsNames(item.status.group))+" ")])]):(item.status.code === _vm.backendLogCodes.DELETED_GROUPS)?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.author_user)+" ")]),_vm._v(" "+_vm._s(_vm.$ml.get("user_deleted_groups"))+": "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getGroupsNames(item.status.group))+" ")])]):(
                item.status.code === _vm.backendLogCodes.UPDATED_GROUP_RULES
              )?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.author_user)+" ")]),_vm._v(" "+_vm._s(_vm.$ml.get("user_updated_group_rules"))+" "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getGroupsNames(item.status.group))+" ")]),(_vm.translateGroupDescription(item.status.message, _vm.$ml))?_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$ml.get("to"))+" "+_vm._s(_vm.translateGroupDescription(item.status.message, _vm.$ml))+" ")]):_vm._e()]):(
                item.status.code ===
                _vm.backendLogCodes.USER_REQUEST_SINGLE_ACCESS
              )?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.author_user)+" ")]),_vm._v(" "+_vm._s(_vm.$ml.get("requested_single_access"))+" "),_c('span',{staticClass:"text-decoration-underline"},[_vm._v(" "+_vm._s(_vm.$ml.get("to_admins"))+" ")])]):(
                item.status.code ===
                _vm.backendLogCodes.USER_REQUEST_PASSWORD_CHANGE
              )?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.author_user)+" ")]),_vm._v(" "+_vm._s(_vm.$ml.get("requested_password_change_description"))+" "),_c('span',{staticClass:"text-decoration-underline"},[_vm._v(" "+_vm._s(_vm.$ml.get("to_admins"))+" ")])]):(
                item.status.code === _vm.backendLogCodes.ADD_VACATION_TO_USER
              )?_c('span',[_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("add_vacation_to_user"))+" ")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" \""+_vm._s(item.status.target_user)+"\". ")]),_vm._v(" "+_vm._s(_vm.$ml.get("vacation_period"))+" "),_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("from").toLowerCase())+" "+_vm._s(_vm.formatDateUTC(item.status.start_date))+" "+_vm._s(_vm.$ml.get("until"))+" "+_vm._s(_vm.formatDateUTC(item.status.end_date))+" ")])]):(
                item.status.code === _vm.backendLogCodes.REMOVE_VACATION_FROM_USER
              )?_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("remove_vacation_from_user"))+" "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.status.target_user)+" ")])]):(
                item.status.code ===
                _vm.backendLogCodes.RECEIVE_USER_SINGLE_ACCESS
              )?_c('span',[_c('span',[_vm._v(" "+_vm._s(item.status.author_user || _vm.$ml.get("not_found"))+" "+_vm._s(_vm.$ml.get("granted_single_access_to"))+" "),_c('b',[_vm._v(_vm._s(item.status.target_user))])])]):(
                item.status.code === _vm.backendLogCodes.LOST_USER_SINGLE_ACCESS
              )?_c('span',[_c('span',[_vm._v(" "+_vm._s(item.status.author_user || _vm.$ml.get("not_found"))+" "+_vm._s(_vm.$ml.get("removed_single_access_from"))+" "),_c('b',[_vm._v(_vm._s(item.status.target_user))])])]):(item.status.code === _vm.backendLogCodes.ACTIVATE_SSO)?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.author_user)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("user_activate_sso"))+" ")])]):(item.status.code === _vm.backendLogCodes.DISABLE_SSO)?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.author_user)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("user_disable_sso"))+" ")])]):(
                item.status.code ===
                _vm.backendLogCodes.CANCEL_ACTIVATION_SSO_ASYNC
              )?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.author_user)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("cancel_activation_sso_async"))+" ")])]):(
                item.status.code === _vm.backendLogCodes.ACTIVATE_SSO_ASYNC
              )?_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.author_user)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("user_activate_sso_async"))+" ")])]):_vm._e()]}},{key:"header.date",fn:function(ref){
              var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" ")]}},{key:"header.browser",fn:function(ref){
              var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" ")]}},{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateAndHour(item.created_at, true))+" ")]}},{key:"header.ip",fn:function(ref){
              var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey darken-1","dark":"","small":""},domProps:{"textContent":_vm._s('mdi-help-circle')}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("ip_address_description")))])])]}},{key:"header.operational_system",fn:function(ref){
              var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey darken-1","dark":"","small":""},domProps:{"textContent":_vm._s('mdi-help-circle')}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("operational_system_description")))])])]}},{key:"header.start",fn:function(ref){
              var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex justify-center align-center flex-nowrap"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v(" mdi-help-circle ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"max-width":"450px"}},[_vm._v(" "+_vm._s(_vm.$ml.get("access_time_start"))+" ")])])]}},{key:"header.end",fn:function(ref){
              var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex justify-center align-center flex-nowrap"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v(" mdi-help-circle ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"max-width":"450px"}},[_vm._v(" "+_vm._s(_vm.$ml.get("access_time_end"))+" ")])])]}},{key:"header.duration",fn:function(ref){
              var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex justify-center align-center flex-nowrap"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v(" mdi-help-circle ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"max-width":"450px"}},[_vm._v(" "+_vm._s(_vm.isMicrosoft ? _vm.$ml .get("access_time_duration") .replace("Google", "Microsoft") : _vm.$ml.get("access_time_duration"))+" ")])])]}},{key:"header.day",fn:function(){return [_vm._v(" "+_vm._s(_vm.$ml.get("date"))+" ")]},proxy:true},{key:"header.records",fn:function(ref){
              var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" ")]}},{key:"item.start",fn:function(ref){
              var item = ref.item;
return [_c('div',{class:item.startFix ? 'incomplete' : 'complete'},[_vm._v(" "+_vm._s(item.start)+" ")])]}},{key:"item.end",fn:function(ref){
              var item = ref.item;
return [_c('div',{class:item.endFix ? 'incomplete' : 'complete'},[_vm._v(" "+_vm._s(item.end)+" ")])]}},{key:"item.duration",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:((item.durFix ? 'incomplete' : 'complete') + " d-flex justify-center align-center flex-nowrap")},'div',attrs,false),item.state != 'COMPLETE' && on),[_vm._v(" "+_vm._s(item.duration)+" "),(item.state != 'COMPLETE')?_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v(" mdi-help-circle ")]):_vm._e()],1)]}}],null,true)},[_c('div',{staticStyle:{"max-width":"450px"}},[_vm._v(" "+_vm._s(item.state == "MISSING_DATA" ? _vm.$ml.get("access_time_duration_missing_data") : item.state == "NOT_FINISHED" ? _vm.$ml.get("access_time_duration_not_finished") : item.state == "CANNOT_CALCULATE" ? _vm.$ml.get("access_time_duration_cannot_calculate") : "")+" ")])])]}},{key:"item.records",fn:function(ref){
                  var item = ref.item;
return [_c('span',[(item.records)?_c('span',_vm._l((item.records.slice(0, 10)),function(record,index){return _c('span',{key:index,staticClass:"mx-1"},[_c('v-chip',{staticClass:"my-1",attrs:{"small":""}},[_vm._v(_vm._s(record.event)+" "+_vm._s(record.hour)+" ")])],1)}),0):_vm._e(),(item.records && item.records.length > 10)?_c('span',[_c('v-chip',{staticClass:"my-1",attrs:{"small":""}},[_vm._v("...")])],1):_vm._e()])]}},{key:"expanded-item",fn:function(ref){
                  var headers = ref.headers;
                  var item = ref.item;
return [_c('td',{staticClass:"pl-4 pt-4 pb-4 grey lighten-4",attrs:{"colspan":headers.length}},[(_vm.expandableLogCodes.includes(item.status.code))?_c('div',[(
                    item.status.code === _vm.customLogCodes.reports_access_time
                  )?_c('span',[_c('v-data-table',{attrs:{"headers":item.headers,"items":item.records,"hide-default-footer":!item.records ||
                      (item.records && item.records.length <= 10)},scopedSlots:_vm._u([{key:"item.event",fn:function(ref){
                      var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.event)+" ")])]}}],null,true)})],1):_vm._e()]):_vm._e()])]}},{key:"item.data-table-expand",fn:function(ref){
                      var item = ref.item;
                      var isExpanded = ref.isExpanded;
                      var expand = ref.expand;
return [(_vm.expandableLogCodes.includes(item.status.code))?_c('v-icon',{class:[
                'v-data-table__expand-icon',
                { 'v-data-table__expand-icon--active': isExpanded } ],on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_vm._v("$expand")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }